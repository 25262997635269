import React from 'react';
import './DetailPiece.css';

import CeramiXr2 from '../../assets/ceramicXr/CeramiXr2.jpg';

const DigitalProtection = ({ onReset }) => {
    return (
        <div className="detail-container">
            <div class="detail-header">之前的“之上”</div>
            <div class="detail-content">
                <h1>非遗徽剧数字化保护和传承方案</h1>
                <div class="detail-content-row">
                    <div className="detail-text max-width">在关注文物保护的道路上，“之上”团队也将视野扩展至我国丰富的地方非物质文化遗产。徽剧，作为中国历史悠久的戏曲剧种之一，拥有超过三百年的传承，成就了独具一格的安徽艺术风情。市场调研揭示，尽管这一文化珍宝极具特色，但在传承与创新，尤其是吸引年轻观众方面，仍面临挑战。</div>
                    {/* <img className="detail-image" src = {CeramiXr1} alt="Digital Protection and Inheritance Plan for Intangible Cultural Heritage Hui Opera"/> */}
                </div>
                <div className='detail-content-row'>
                    <img className="detail-image" src = {CeramiXr2} alt="Digital Protection and Inheritance Plan for Intangible Cultural Heritage Hui Opera"/>
                    <div className="detail-text">为此，我们致力于运用最新的沉浸式技术，开发了一系列数字化保护和传承徽剧的方案，其中包括 3D 扫描与建模，虚拟现实和交互体验设计等技术，打造一个线上的徽剧数字博物馆，以实现对这一传统艺术形式的现代保护。并且，结合安徽省徽剧院5的实际情况，我们还组织了系列主题沉浸式艺术展，以此吸引年轻一代走近徽剧，参与到这一传统艺术的现代传承之中。</div>
                </div>
                <button onClick={() => { onReset(); }}>收起</button>
            </div>
        </div>
    );
}

export default DigitalProtection;