import React from 'react';

import CeramiXr1 from '../../assets/ceramicXr/CeramiXr1.jpg';
import CeramiXr2 from '../../assets/ceramicXr/CeramiXr2.jpg';

const VrTraining = ({ onReset }) => {
    return (
        <div className="detail-container">
            <div class="detail-header">之前的“之上”</div>
            <div class="detail-content">
                <h1>VR技术促进训练发展</h1>
                <div class="detail-content-row">
                    <div className="detail-text">在“CeramiXR”项目成功后，我们深感肩负着使命，要将祖国深厚的历史文化宝库与数字创新结合，共绘数字中国的未来。在市场调研中我们发现，现当下虚拟现实技术的应用还停留在游戏或是娱乐行业，但实际上沉浸式技术的应用在文旅行业有着巨大潜力。</div>
                    <img className="detail-image" src = {CeramiXr1} alt="Digital Protection and Inheritance Plan for Intangible Cultural Heritage Hui Opera"/>
                </div>
                <div className='detail-content-row'>
                    <img className="detail-image" src = {CeramiXr2} alt="Digital Protection and Inheritance Plan for Intangible Cultural Heritage Hui Opera"/>
                    <div className="detail-text">因此我们首先将目光投向了中国数字化建设最好的敦煌研究院4，我们设计开发一款兼教育和专业培训的沉浸式应用关于石窟的文物修复训练系统。目前关于这款应用的原型机已经研发完成，它也在首届 XR 开发者大赛(2023 中国赛区)中获得了“最佳创新奖”。</div>
                </div>
                <button onClick={() => { onReset(); }}>收起</button>
            </div>
        </div>
    );
};

export default VrTraining;
