import React from 'react';

import MenuBar from './component/MenuBar';
import './StartPage.css';

const StartPage = () => {

  return (
    <div>
      <MenuBar />
      <div className="page-container">
        <div className="logo-container">
          <div className="circle2">
            <div className="circle1"></div>
            <div className="circle3"></div>
          </div>
        </div>
        <div className="text-elements">
          <h1 >之上 · 2024</h1>
          <p>ApeXR Lab</p>
        </div>
      </div>
    </div>
  );
  
};

export default StartPage;

