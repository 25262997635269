import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Slider from "react-slick"

import PiecesCard from './component/PiecesCard';
import MemberCard from './component/Members'
import BusinessCard from './component/BusinessCard';
import MenuBar from './component/MenuBar';

import './MainPage.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import CeramiXr from './component/DetailPieceCard/CeramiXr';
import VrTraining from './component/DetailPieceCard/VrTrainning';
import DigitalProtection from './component/DetailPieceCard/DigitalProtection';
import backgroundImage from './assets/background.jpg'

import businessCardText from './component/describeBusinessCard.json';
import membersData from './component/describeMemberCard.json';
import piecesData from './component/describePiecesCard.json';

// piece card slider settings
var sliderSettings = {
  dots: true,
  arrows: false,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  speed: 1000,
  autoplaySpeed: 4000,
  cssEase: "linear",
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

// Function to chunk the array
const chunkArray = (array, chunkSize) => {
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
};

const MemberCards = () => {
  // Splitting the memberCardArray into chunks of 2
  const memberGroups = chunkArray(membersData, 2);

  return (
    <div className="member-card-row">
      {memberGroups.map((group, index) => (
        <div key={index} className="member-group">
          {group.map(member => (
            <MemberCard key={member.id} member={member} />
          ))}
        </div>
      ))}
    </div>
  );
};

const MainPage = () => {
  const [selectedPieceId, setSelectedPieceId] = useState(null);

  const location = useLocation();
  useEffect(() => {
    const hash = location.hash.replace("#", "");
    if (hash) {
      const element = document.getElementById(hash);
      if (element) {
        const offsetTop = element.getBoundingClientRect().top + window.pageYOffset - 80; // -80px or your desired offset
        window.scrollTo({ top: offsetTop, behavior: "smooth" });
      }
    }
  }, [location, location.hash]);
  

  const handlePieceClick = (id) => {
    setSelectedPieceId(id);
    console.log('set id:'+id);
  };
  const resetSelectedPiece = () => {
    setSelectedPieceId(null);
    console.log('reset id: ' + selectedPieceId)
  };

  return (
    
<div >
  <MenuBar/>
  <img src={backgroundImage} className="content-below-menu" alt=""></img>

  <div >
    <h1 className="heading" id="about">关于“之上”</h1>
      <div className='pieces-container text-describtion'>
        <p>&emsp;&emsp;“之上”是一支年轻且优秀的沉浸式技术1应用开发团队。随着数字中国建设的全面发展，之上团队更注重与发挥沉浸式技术在各种实体行业中功能性的应用。通过创新的沉浸式交互体验设计理念和成熟的沉浸式技术开发能力，帮助各种实体行业快速融入到数字中国建设的步伐中。<br/>
        &emsp;&emsp;之上成立初期以文旅，艺术和教育行业为主要阵地，力求打造国内顶级的沉浸式应用，例如非遗数字化，沉浸式数字艺术展，博物馆和教育机构的混合现实应用开发等等。我们坚信，古老的中国文化在现代科技的火花碰撞下，能焕发出新的生机。我们不仅不仅追求技术革新，也将目光聚焦于弘扬中华传统文化、讲好中国党建故事和打造文化城市新地标等等, 承担起高度围绕党和国家方针政策“在中华优秀传统文化中不断融入新的技术成果，实现创新发展”的责任。</p> 
        <div className="start-circle1"></div>
        <div className="start-circle2"></div>
        <div className="start-circle3"></div>  
      </div>

    <h1 className="heading">“之上”业务范围</h1>
    <div className="pieces-container">
        <BusinessCard
          imageUrl=""
          text={businessCardText.overlayText} 
        />
    </div>
      
    <h1 className="heading">核心成员</h1>
    <div className="pieces-container"> 
      <MemberCards />
    </div>
     

    <h1 className="heading" id="artwork">作品集</h1>
    
    <div className="slider-container">
      <Slider {...sliderSettings}>
        {piecesData.map(piece => (
          <PiecesCard
            key={piece.id}
            name={piece.Name}
            photo={piece.Photo}
            text={piece.Text}
            onClick={() => handlePieceClick(piece.id)}
          />
        ))}
      </Slider>
    </div >
    <div className='pieces-container' >
      {selectedPieceId === "0" && <DigitalProtection onReset={resetSelectedPiece}/>}
      {selectedPieceId === "1" && <CeramiXr onReset={resetSelectedPiece}/>}
      {selectedPieceId === "2" && <VrTraining onReset={resetSelectedPiece}/>}
    </div>

  </div>
  );
</div>



  );
};

export default MainPage;
