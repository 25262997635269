import React from 'react';
import './PiecesCard.css';

import './DetailPieceCard/CeramiXr'
import './DetailPieceCard/VrTrainning'

// PiecesCard.js
const PiecesCard = ({ id, name, photo, text, onClick }) => {
  return (
    <div className="pieces-card" onClick={() => onClick(id)}>
      
      <img src={photo} alt={name} />
      <div className='textbox'>
        <p >之前的“之上”</p>
        <h2 >{name}</h2>
        {text && <p className='describe'>{text}</p>}
      </div>
    </div>
  );
};
export default PiecesCard;
