import React from 'react';

import StartPage from './StartPage';
import MainPage from './MainPage';
import NotFound from './NotFoundPage';
import { HashRouter, Routes, Route } from "react-router-dom";


const App = () => {
  return (
    <div>
      <HashRouter>
        <Routes>
          <Route path="/" element={<StartPage />} />
          <Route path="/main" element={<MainPage />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </HashRouter>
    </div>
    );
};

export default App;

