import React from 'react';

const NotFound = () => {
    return (
        <div style={{ textAlign: 'center', marginTop: '50px', color:'whitesmoke'}}>
            <h1>404 Not Found</h1>
            <p>Sorry, the page you visited does not exist.</p>
            <p>抱歉，您訪問的頁面不存在。</p>
        </div>
    );
}

export default NotFound;