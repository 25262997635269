import React from 'react';
import './Members.css'; 

const MemberCard = ({ member }) => {
  return (
    <div className="member-card">
      <div className="avatar">
        <img src={member.Avatar} alt={member.Name} className='avatar'/>
      </div>
      <div className="info">
        <h2>{member.Name}</h2>
        <p>{member.Text}</p>
      </div>
    </div>
  );
};

export default MemberCard;
