import React from 'react';

import CeramiXr1 from '../../assets/ceramicXr/CeramiXr1.jpg';
import CeramiXr2 from '../../assets/ceramicXr/CeramiXr2.jpg';

const CeramiXr = ({ onReset }) => {
    return (
        <div className="detail-container">
            <div class="detail-header">之前的“之上”</div>
            <div class="detail-content">
                <h1>CeramiXR—沉浸式数字陶艺艺术展览</h1>
                <div class="detail-content-row">
                    <img className="detail-image" src = {CeramiXr1} alt="Digital Protection and Inheritance Plan for Intangible Cultural Heritage Hui Opera"/>
                    <div className="detail-text">2023 年的一月，“之上”刚在澳洲成立，便承接了悉尼的沉浸式数字陶艺艺术展览‘CeramiXR’展览的项目，为其提供了完整的沉浸式艺术展览的技术方案，并设计和开发了其中的沉浸式应用。其中便包括了陶艺艺术品的 3D 扫描和建模，VR 展览的制作，用户体验设计，交互设计，嵌入式技术开发等等。展览赢得了悉尼观众的广泛赞誉，观众更是涵盖了从中小学中的孩子到许多艺术机构和画廊的负责人。</div>
                </div>
                <div className='detail-content-row'>
                    <img className="detail-image max-width" src = {CeramiXr2} alt="Digital Protection and Inheritance Plan for Intangible Cultural Heritage Hui Opera"/>
                </div>
                <button onClick={() => { onReset(); }}>收起</button>
            </div>
        </div>
    );
};

export default CeramiXr;
