import React from 'react';
import './BusinessCard.css';
import assets from './describeBusinessCard.json';

const BusinessCard = () => {
  return (
    <>
      {assets.map((asset, index) => (
        <div key={index} className="background-image-container">
          <img src={asset.backgroundImage} alt={`Background ${index}`} className="blurred-image" />
          <div className="text-overlay">{asset.overlayText}</div>
        </div>
      ))}
    </>
  );
};

export default BusinessCard;
