import React from 'react';
import './MenuBar.css';
import logo from '../assets/MenuBar/logo.png';
import { Link } from 'react-router-dom';

const Menu = () => {

  return (
    <div className="menu">
      <img src={logo} alt="Logo" />
      <Link to="/"><p className='companyName'>ApexR Lab</p></Link>
      
      <div className="menuContainer">
        <Link to="/main#about"><p className='menuItem'>关于</p></Link>
        <Link to="/main#artwork"><p className='menuItem'>作品集</p></Link>
      </div>
    </div>
  );
};

export default Menu;
